export enum Option {
    //菜单对话框相关的写这里
    ChangeMenu = "ChangeMenu",
    ShowDialog = "ShowDialog",
}
/*
    请！不！要！在！菜！单！和！vuex mution&action！之！外！的！地！方！使！用！NameSpace！
*/
export enum NameSpace {
    Menu = "menu",
    Quick = "quick",
    People = "people",
    Activity = "activity",
    Notice = "notice",
    //叫health不叫physcial exam的原因是，不止是体检
    Health = 'health',
    Info = 'info',
    Party = 'party',
    //走访慰问
    Call = 'call',//Call有短暂拜访的意思
    //工作人员管理
    Admin = "admin",
    Global="global",
}

//VUE路由表
export enum routerMap {
    /* ~~~~~~~~~~~~~每行都要加逗号呀~~~~~~~~~~~~ */
    /* ··········记得在菜单里配置一下············ */
    /*主页部分开始*/
    quickOverView = '/quick-overview',
    quickAdminInfo = '/quick-admin-info',
    /*主页部分结束*/
    // ♻ 👀 🔄 📤 ，可以放emoji？嘤嘤嘤(前面的泥垢了！！)
    about = '/about',
    peopleAdd = '/people-add',
    peopleCheck = '/people-check',
    peopleOptionAndInfo = '/people-option-and-info',
    peopleMassEnroll = "/people-mass-enroll",
    //已故人员
    peopleDead = "/people-dead",
    peopleDeadInfo="/people-dead-info",
    noticeAdd = '/notice-add',
    noticeCheck = '/notice-check',
    noticeInfo = '/notice-info',
    activityAdd = '/activity-add',
    activityCheck = '/activity-check',
    activityOptionAndInfo = '/activity-option-and-info',
    activityEnroll = "/activity-enroll",
    activityNowCount = "/activity-now-count",
    activityYearCount="/activity-year-count",
    infoOfWage = "/info-of-wage",
    infoOfHealth = "/info-of-health",
    infoOfActivity = "/info-of-activity",
    PartyCheck = "/party-check",
    PartyPeopleCheck = "/party-people-check",
    PartyPeopleManage = "/party-people-manage",
    /*走访慰问*/
    callAdd = "/call-add",
    callCheck = "/call-check",
    callInfo = '/call-info',
    /*工作人员管理 */
    adminAdd = "/admin-add",
    adminAuth = "/admin-auth",
    adminList = "/admin-list",
    adminInfo = "/admin-info",
    /*文件管理*/
    fileUpload = "/file-upload",
    fileCheck = "/file-check",

}
//这个是URL和后台交互的！！！，因为有些URL要写多次！！！！为了方便修改，在这里写一次，方便后续。
export const AxiosURLMap = {
    //如果是混合查询，返回的不止一类信息，比如一起返回人员与活动，
    //一定要把URL写全，通过谁的ID，通过谁的name
    /*人员*/
    Login: '/login',

    //纯天然人员的
    MassEnroll: "/people/mass-enroll",
    GetPeopleByname: "/people/get-people-by-name",
    GetPeopleByCondition: "/people/get-people-by-condition",
    GetPeopleByConditionLen:"/people/get-people-by-condition-Len",
    GetPeopleById: "/people/get-people-by-id",
    ResetWxPassword: "/people/reset-wx-password",
    ChangePeopleById: "/people/change-people-by-id",
    ChangePeopleDeadById: "/people/change-people-dead-by-id",
    AddPeople: "/people/add-people",
    AddPeopleImage: "/people/add-people-image",

    AddPeopleDead: "/people/add-people-dead",
    GetPeopleDeadByCondition: "/people/get-people-dead-by-condition",
    GetPeopleDeadByConditionLen: "/people/get-people-dead-by-condition-len",
    GetPeopleDeadById:"/people/get-people-dead-by-id",
    GetPeopleDeadAll:"/people/get-all-people-dead",
    GetAllDeadPeopleLen:"/people/get-all-dead-people-len",
    //导出所有人
    GetAllPeople:"/people/get-all-people",
    // 查看所有人总数
    GetAllPeopleLen:"/people/get-all-people-len",
    GetPeopleBatch:"/people/get-people-batch",
    //  查看所在片区
    GetPeopleByPianQu:"/people/get-people-by-pianqu",
    GetPeopleByPianQuLen:"/people/get-people-by-pianqu-len",
    //  查看所有片区长度


    /*活动*/
    //通过活动ID返回参与的人员信息
    GetPeopleByActivityId: "/activity/get-people-by-activityid",
    //得到参加活动的所有人
    GetAllPeopleByActivityId: "/activity/get-all-people-by-activityid",
    //在一个活动中，通过人名在活动中找人，找不到拉倒
    GetPeopleByActivityIdAndPeopleName: "/activity/get-people-by-activity-id-and-peoplename",
    //得到活动总人数
    GetPeopleLengthByActivityId: "/activity/get-people-length-by-activityid",
    //得到全部活动
    GetAllActivity:"/activity/get-all-activity",
    //得到全部活动数
    GetALLActivityLen:"/activity/get-all-activity-len",

    //得到活动日志
    GetActivityLog:"/activity/get-activity-log",

    GetFamilyById:"/activity/get-family-by-id",
    DelActivityById: "/activity/del-activity-by-id",
    AddActivity: "/activity/add-activity",
    GetNowActivity: "/activity/get-now-activity",
    GetActivityById: "/activity/get-activity-by-id",
    ChangeActivityById: "/activity/change-activity-by-id",
    GetNowActivityStatusByPeoId: "/activity/get-now-activity-status-by-peoid",
    JoinActivity: "/activity/join-activity",
    CancelJoinActivity: "/activity/cancel-join-activity",
    GetJoinedActivityByPeoId: "/activity/get-joined-activity-by-peoid",
    SubmitStatusActivity: "/activity/submit-status-activity",
    SubmitStatusMassActivity: "/activity/submit-status-mass-activity",
    UnSubmitStatusActivity: "/activity/unsubmit-status-activity",
    GetNowActivityCount: "/activity/get-now-activity-count",
    GetActivityKind: "/activity/get-activitykind",
    SetActivityKind: "/activity/set-activitykind",
    AddActivityKind:"/activity/add-activitykind",
    ChangeActivityKind:"/activity/change-activitykind",
    DelActivityKind:"/activity/del-activitykind",
    GetActivityByYearAndKind: "/activity/get-activity-by-year-and-kind",
    GetActivityNamebyId: "/activity/get-activityname-by-id",
    /*通知啦~*/
    AddNotice: "/notice/add-notice",
    GetNotice: "/notice/get-notice",
    GetNoticeLen: "/notice/get-notice-len",
    ChangeNoticeById: "/notice/change-notice-by-id",
    GetNoticeById: "/notice/get-notice-by-id",
    DelNoticeById: "/notice/del-notice-by-id",
    /*信息上传 */
    InfoOfHealth: "/info/info-of-health",
    InfoOfWage: "/info/info-of-wage",
    infoOfActivity: "/info/info-of-activity",
    /*党建管理*/
    GetPartyBranch: '/party/get-party-branch',
    GetPartyBranchLen: '/party/get-party-branch-len',
    GetPartyBranchPeople: '/party/get-partybranch-people',
    GetPartyBranchPeoplelen: '/party/get-partybranch-people-len',
    //找人，在党支部里面找~~~
    GetPeopleByNameInParty: '/party/get-people-by-name-in-party',
    //变更支部书记
    ChangeSecretary: "/party/change-secretary",
    GetPartyInfoOfPeopleid: "/party/get-party-info-of-peopleid",
    //变更人员所属支部
    ChangePeopleParty:"/party/change-people-party",
    //新增成员到党支部
    AddNewPeople:"/party/add-newpeople",
    //删除人员
    DelBranchPeople:"/party/del-branch-people",

    /*管理员*/
    GetAdminInfo: "/admin/get-admin-info",
    GetOtherAdminsInfo: "/admin/get-other-admins-info",
    GetOtherAdminsInfoLen: "/admin/get-other-admins-info-len",
    ChangeAdminAuth: "/admin/change-admin-auth",
    DelAdmin:"/admin/del-admin",
    AddAdmin: "/admin/add-admin",
    //主页的，改管理员信息与密码
    ChangeAdminPass: "/admin/change-admin-pass",
    ChangeAdminInfo: "/admin/change-admin-info",

    /*慰问*/
    AddCall: "/call/add-call",
    GetNowCall: "/call/get-now-call",
    GetCallByYearAndKind: "/call/get-call-by-year-and-kind",
    ChangeCallById: "/call/change-call-by-id",
    GetCallById: "/call/get-call-by-id",
    DelCallById: "/call/del-call-by-id",
    UpExcel:"/call/up-excel",
    GetCall:"/call/get-call",
    GetCallLen:"/call/get-call-len",

    /*团队管理*/
    AddTeam: "/team/add-team",
    // GetNowCall: "/call/get-now-call",
    GetTeam: "/team/get-team",
    GetTeamLen: "/team/get-team-len",
    GetTeamById: "/team/get-team-by-id",
    DelTeamById: "/team/del-team-by-id",
    ChangeTeamById: "/team/change-team-by-id",
    GetTeamInfoOfPeopleid: "/team/get-team-info-of-peopleid",
    // /*志愿者管理*/


    //党建管理-骨干队伍-人员条件查找
    GetPartyPeopleByConditionForZju: "/people/get-party-people-by-condition-for-zju",
    GetPartyPeopleByConditionForZjuLen:"/people/get-party-people-by-condition-for-zju-len",

    /*生日贺卡*/
    GetPeopleBirthdayByAge:'/people/get-people-birthday-by-age',

    //生日备注修改
    ChagneBirthRemark:'/people/change-birth-remark',
     // 问卷调查-新建问卷
     AddQuestionnaire:'/people/add-questionnaire',
     //问卷调查-删除问卷
     DelQuestionnaire:'/people/del-questionnaire',
     // 问卷调查-问卷结果统计
     GetQuestionnaireresult:'/people/get-questionnaire-result',
     //问卷调查-删除问卷结果
     DelQuestionnaireresult:'/people/del-questionnaire-result'
}
