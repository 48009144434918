










import Vue from 'vue'
import {Option,NameSpace,routerMap} from "../storehelper"
import PopDialog from "../components/PopDialog.vue"
export default Vue.extend({
    components:{
        PopDialog
    },
    methods:{
        changeMenu(){
           this.$store.commit(NameSpace.Menu +"/"+ Option.ChangeMenu);
        },
        back(){
            this.$router.go(-1)
        },
        home(){
             this.$router.push('/')
             this.$store.dispatch(NameSpace.Global+'/'+Option.ShowDialog,{
                 text:"回到首页"
             } )
        }
    }
})
   
