
/*
    请！不！要！在！菜！单！和！vuex mution&action！之！外！的！地！方！使！用！NameSpace！
*/

//菜单配置项  NameSpace后面有斜杠！！！！！！！！！
/*
    ------------- NameSpace后面有斜杠！！！！！！！！！-------------
    ------------- NameSpace后面有斜杠！！！！！！！！！-------------
    ------------- NameSpace后面有斜杠！！！！！！！！！-------------
    NameSpace用来分割vuex的mutations与action! 也用来分割菜单种类。
    请！不！要！在！菜！单！和！vuex！之！外！的！地！方！使！用！NameSpace！

*/
interface Menuitem {
    title: string,
    path: Array<string>,

}

export interface MenuNamespace {
    title: string
    space: string
    icon?: string
    smalldevice: boolean
    sub: Array<Menuitem>

}

import {NameSpace,routerMap} from "@/sharevue/storehelper"

export enum zjutNameSpace{
    //团队管理
    Team = 'team',
    //志愿者管理
    Volunteer="volunteer",
    File = 'file',
    //管理员管理
    admin="admin",
    //生日贺卡管理
    Birthday='birthday',
      //问卷调查
      Questionnaire='questionnaire',
}

export enum zjutrouterMap{
    /*团队管理*/
    teamAdd = "/team-add",
    teamCheck = "/team-check",
    teamInfo = '/team-info',
    /*志愿者管理 */
    volunteerRegister = "/volunteer-register",
    volunteerTeam = "/volunteer-team",
    volunteerModify="/volunteer-modify",
    volTeamModify="/volTeam-modify",
    volTeamPerson="/volTeam-person",
    volunteerActivityAdd = "/volunteer-activity-add",
    volunteerActivityAddInfo = "/volunteer-activity-add-info",
    volunteerActivityCount = "/volunteer-activity-count",
    volunteerActivityCountInfo = "/volunteer-activity-count-info",
    /*文件管理模块*/
    fileAdd = "/file-add",
    fileCheck = "/file-check",
    fileInfo="/file-info",
    /*党费管理模块*/
    PartyFee="/party-fee",
    PartyFeeDetail="/party-fee-detail",
    PartyFeeManage="/party-fee-manage",
    /*生日贺卡*/
    BirthdayCard="/birthday-card-mail" ,
    BirthdaySta="/birthday-sta",
    /*问卷调查*/
    QuestionnaireAdd="/questionnaire-add" ,
    QuestionnaireRes="/questionnaire-res",
}

export const zjutAxiosURLMap = {
    /*人员管理*/
    GetPeoplebyMultipleCondition:"/people/get-people-by-multiple-condition",
    GetPeopleLenbyMultipleCondition:"/people/get-people-len-by-multiple-condition",
    ZjutMassEnroll:"/people/zjut-mass-enroll",
    /*双职工*/
    GetEmployee:"/people/get-employee",
    GetEmployeeLen:"/people/get-employee-len",
    //改进型双职工-findByName
    GetEmployeeByName:"/people/get-employee-by-name",
    GetPeopleIdByName:"/people/get-people-id-by-name",
    /*团队管理*/
    AddTeam: "/team/add-team",
    GetTeam: "/team/get-team",
    GetTeamLen: "/team/get-team-len",
    GetTeamById: "/team/get-team-by-id",
    DelTeamById: "/team/del-team-by-id",
    ChangeTeamById: "/team/change-team-by-id",

    /*活动管理*/
    GetActivityYearCount:"/activity/get-activity-year-count",
    GetNoActivityPeople:"/activity/get-no-activity-people",
    /*志愿者管理*/
    GetCheckPeople:"/volunteer/get-check-people",//检查是否在人员表里
    JudgeVolunteer:"/volunteer/judge-volunteer",//检查是否是志愿者
    AddVolunteer:"/volunteer/add-volunteer",//VolunteerReg 106
    GetVolunteer:"/volunteer/get-volunteer",//VolunteerInfo 65
    GetVolunteerLen:"/volunteer/get-volunteer-length",//VolunteerInfo 96
    GetVolunteerById:"/volunteer/get-volunteer-by-id",//volunteerModify 31
    DelVolunteerById:"/volunteer/del-volunteer-by-id",//VolunteerInfo 122
    ChangeVolunteerById:"/volunteer/change-volunteer-by-id",//VolunteerModify 108
    GetCheckTeam:"/volunteer/get-check-team",
    AddVolTeam:"/volunteer/add-volunteer-team", //VolTeamReg 122
    GetVolTeam:"/volunteer/get-vol-team",//VolTeamInfo 75
    GetVolTeamLen:"/volunteer/get-vol-team-len",//VolTeamInfo 108
    DelVolTeamById:"/volunteer/del-vol-team-by-id",//VolTeamInfo 133
    GetVolTeamById:"/volunteer/get-vol-team-by-id",
    ChangeVolTeamById:"/volunteer/change-vol-team-by-id",//VolTeamModify 106

    //////////////////
    GetCheckActivity:"/volunteer/get-check-activity",
    AddVolActivity:"/volunteer/add-vol-activity",//VolunteerActivityApply 126
    //GetVolunteerBycondition:"/volunteer/get-volunteer-by-condition",// VolunteerActivityApply 159
    GetActivity:"/volunteer/get-activity",//VolunteerActivityShow 73
    DelActivityById:"/volunteer/del-activity-by-id",//VolunteerActivityShow 102
    GetActivityLen:"/volunteer/get-activity-len",//VolunteerActivityShow 120
    GetActivityById:"/volunteer/get-activity-by-id",//VolunteerActivityAddInfo 119
    ChangeActivityById:"/volunteer/change-activity-by-id",//VolunteerActivityAddInfo 150
    GetVolunteerByName:"/volunteer/get-volunteer-by-name",//VolunteerActivityCount 78   VolTeamReg 165
    GetJoinedActivityById:"/volunteer/get-joined-activity-by-id",//VolunteerActivityCountInfo 62

    /*导出所有人 */
    GetAllPeople:"/people/get-all-people",

    /*得到礼品抽奖码*/
    GetCode:"/get-code",
    GetGift:"/get-gift",
    CheckGift:"/check-gift",

    /*会议纪要*/
    AddFile:"/file/add-file",
    GetFileByUserId:"/file/get-file-by-userid",
    GetFileById:"/file/get-file-by-id",
    DownloadFile:"/file/download",
    GetFileByYearAndBranch:"/file/get-file-by-year-and-branch",
    DelFileByIdAndFileIndex:"/file/del-file-by-id-and-fileindex",
    ChangeFile:"/file/change-file",

    /*党费上缴模块*/
    // GetSecretaryName:"/party/get-secretary-name",
    //管理员查找党费信息
    GetPartyFee: "/party/get-party-fee",
    ChangePartyFee: "/party/change-party-fee",
    GetPartyFeeLength:"/party/get-party-fee-length",
    //支部书记查找党费信息，只能查找自己支部的党费信息
    GetSecretary:"/party/get-secretary",
    // GetPartyFeeByBranch:"/party/get-party-fee-by-branch",
    GetPartyFeeById:"/party/get-party-fee-by-id",

    //管理员管理模块//
    GetAllAdmin: "/admin/get-all-admin",


}

export let Menus: Array<MenuNamespace> = [
    {
        title: '主页',
        space: NameSpace.Quick,
        icon: "iconfont icon-search-outline",
        smalldevice: true,
        sub: [
            //每个sub下，可能有若干个子页面，打开默认是frist
            { title: "概览与搜索", path: [routerMap.quickOverView], },
            { title: "个人信息", path: [routerMap.quickAdminInfo], }
        ]
    },
    {
        title: "人员管理",
        space: NameSpace.People,
        icon: "iconfont icon-person-outline",
        smalldevice: true,
        sub: [
            { title: "信息录入", path: [routerMap.peopleAdd] },
            { title: "查看与管理", path: [routerMap.peopleCheck, routerMap.peopleOptionAndInfo] },
            { title: "已故人员", path: [routerMap.peopleDead,routerMap.peopleDeadInfo] },
            //{ title: "批量导入", path: [routerMap.peopleMassEnroll]},
        ]
    },
    {
        title: "活动管理",
        space: NameSpace.Activity,
        icon: "iconfont icon-people-outline",
        smalldevice: true,
        sub: [
            // { title: "正在进行", path: "777" },
            { title: "活动发布", path: [routerMap.activityAdd] },
            { title: "查看与管理", path: [routerMap.activityCheck, routerMap.activityOptionAndInfo, routerMap.activityEnroll] },
            { title: "现活动统计", path: [routerMap.activityNowCount, routerMap.activityYearCount] }
        ]
    },
    // 健康管理需求不清，缓--->凉
    /*{
        title:'健康管理',
        space:NameSpace.Health,
        sub: [
            //{ title: "正在进行", path: "/nowpyhsicalexam" },
            { title: "体检导入", path: [routerMap.physicalExamAdd ]},
            { title: "体检查看", path: [routerMap.physicalExamCheck ]},
          ]
    },*/
    {
        title: '通知管理',
        space: NameSpace.Notice,
        icon: "iconfont icon-bubbles-chat",
        smalldevice: true,
        sub: [
            { title: "通知发布", path: [routerMap.noticeAdd] },
            { title: "通知查看", path: [routerMap.noticeCheck, routerMap.noticeInfo] },
        ]
    },
    {
        title: '信息上传',
        space: NameSpace.Info,
        icon: "iconfont icon-folder-",
        smalldevice: false,
        sub: [
            // { title: "活动费", path: [routerMap.infoOfActivity] },
            // { title: "体检信息", path: [routerMap.infoOfHealth] },
            { title: "养老金上传", path: [routerMap.infoOfWage] },
        ]
    },
    {
        title: '党建管理',
        space: NameSpace.Party,
        icon: "iconfont icon-settings--outline",
        smalldevice: true,
        sub: [
            { title: "支部查看", path: [routerMap.PartyCheck, routerMap.PartyPeopleCheck] },
            { title: "人员管理", path: [routerMap.PartyPeopleManage] },
            { title: "党费收缴", path: [zjutrouterMap.PartyFee,zjutrouterMap.PartyFeeDetail] },
            { title: "上缴管理", path: [zjutrouterMap.PartyFeeManage] },
        ]
    },

    /*------------------权限控制---------------
    {
        title:'事务审批',
        space:NameSpace.Party,
        icon:"iconfont icon-checkmark-outline",
        sub:[
            { title: "人员权限",path: [routerMap.PartyManage]},
            { title: "人员管理",path: [routerMap.PartyPeoplemanage]},
        ]
    },*/
    {
        title: '走访慰问',
        space: NameSpace.Call,
        icon: "iconfont icon-message-square-outli",
        smalldevice: true,
        sub: [
            { title: "名单上报", path: [routerMap.callAdd] },
            { title: "信息查看", path: [routerMap.callCheck, routerMap.callInfo] },
        ]
    },

    {
        title: '团队管理',
        space: zjutNameSpace.Team,
        icon: "iconfont icon-Cloud",
        smalldevice: true,
        sub: [
            { title: "团队上报", path: [zjutrouterMap.teamAdd] },
            { title: "团队查看", path: [zjutrouterMap.teamCheck, zjutrouterMap.teamInfo] },
        ]
    },
    /* ------------------权限控制--------------- */
    {
        title: '员工管理',
        space: NameSpace.Admin,
        icon: "iconfont icon-box-",
        smalldevice: true,
        sub: [
            { title: "新增人员", path: [routerMap.adminAdd] },
            { title: "权限配置", path: [routerMap.adminAuth] },
        ]
    },

    {
        title: '志愿者管理',
        space: zjutNameSpace.Volunteer,
        icon: "iconfont icon-checkmark-outline",
        smalldevice: true,
        sub: [
            { title: "注册管理", path: [zjutrouterMap.volunteerRegister,zjutrouterMap.volunteerModify] },
            { title: "队伍管理", path: [zjutrouterMap.volunteerTeam,zjutrouterMap.volTeamModify] },
            { title: "活动上报", path: [zjutrouterMap.volunteerActivityAdd,zjutrouterMap.volunteerActivityAddInfo] },
            { title: "活动管理", path: [zjutrouterMap.volunteerActivityCount,zjutrouterMap.volunteerActivityCountInfo] },
        ]
    },
    {
        title: '会议纪要',
        space: zjutNameSpace.File,
        icon: "iconfont icon-folder-outline",
        smalldevice: true,
        sub: [
            { title: "纪要上传", path: [zjutrouterMap.fileAdd] },
            { title: "纪要查看", path: [zjutrouterMap.fileCheck,zjutrouterMap.fileInfo] },
        ]
    },
    {
        title: '生日贺卡',
        space: zjutNameSpace.Birthday,
        icon: "iconfont icon-folder-outline",
        smalldevice: true,
        sub: [
            { title: "当月寿星", path: [zjutrouterMap.BirthdayCard] },
            { title: "本年度寿星统计", path: [zjutrouterMap.BirthdaySta] },
        ]
    },
    {
        title: '问卷调查',
        space: zjutNameSpace.Questionnaire,
        icon: "iconfont icon-bubble-rounded-",
        smalldevice: true,
        sub: [
            { title: "新建问卷", path: [zjutrouterMap.QuestionnaireAdd] },
            { title: "问卷结果统计", path: [zjutrouterMap.QuestionnaireRes] },
        ]
    },
] 
//加载path-key
//一些接口
interface menusitem {
    namespace: string,
    showsubindex: number,
    //中文名字，用来判断权限
    title: string
    subtitle: string
}
interface MenusMap {
    [key: string]: menusitem;
}
let menusmap: MenusMap = {};
//加载菜单path:菜单内容
Menus.forEach(spaces => {
    for (let i = 0; i < spaces.sub.length; i++) {
        let subitem = spaces.sub[i];
        for (let j = 0; j < subitem.path.length; j++) {
            // 加k-v
            menusmap[subitem.path[j]] = {
                namespace: spaces.space,
                showsubindex: i,
                //中文名字，用来判断权限
                title: spaces.title,
                subtitle: subitem.title
            };
        }
    }
});
export {menusmap as Menusmap}


