










import Vue from "vue";
import { mapMutations, mapState } from 'vuex';
export default Vue.extend({
  watch :{
  },
  methods: {
    control() {
      this.$store.state.global.dialogShow = false;
    }
  }
});
